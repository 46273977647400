import { render, staticRenderFns } from "./set_status_modal_wrapper.vue?vue&type=template&id=40f82031&"
import script from "./set_status_modal_wrapper.vue?vue&type=script&lang=js&"
export * from "./set_status_modal_wrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports